import apiClient from "@/services/axios";
import ExcelJS from "exceljs";
import JsPDF from "jspdf";
function isNumber(value) {
  return !isNaN(Number(value));
}

const PvStyles = {
  all: {
    border: {
      top: { style: "medium", color: { argb: "4A8A9E" } },
      left: { style: "medium", color: { argb: "4A8A9E" } },
      bottom: { style: "medium", color: { argb: "4A8A9E" } },
      right: { style: "medium", color: { argb: "4A8A9E" } },
    },
    alignment: {
      horizontal: "center",
      vertical: "middle",
    },
    nameAlignment: {
      horizontal: "center",
      vertical: "right",
    },
  },
  ModuleHeaders: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ABE36E" },
  },
  secondHeader: {
    moy: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC961" },
    },
    moduleMoy: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "8CC24A" },
    },
    moduleTotal: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF" },
    },
    subject: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF" },
    },
  },
};
const getCertificationNameByMoyenne = function (moyenne, certifs) {
  for (const cert of certifs)
    if (
      Number(moyenne) >= Number(cert.min) &&
      Number(moyenne) <= Number(cert.max)
    ) {
      return cert.name;
    }

  return "";
};
const getModules = async (level, type, trimester) => {
  let listModules = [];
  await apiClient
    .post("/bulletin/v2/module/filter", {
      query: {
        status: "active",
        level: level,
        trimester: trimester,
        type: type,
      },
    })
    .then((res) => {
      listModules = res.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return listModules;
};
const printPVBulletin = async (config) => {
  const {
    selectedLevel,
    schoolDetails,
    selectedStudent,
    selectedStudentName,
    settings,
    type,
    selectedTrimester,
    bulletinType,
    certifs,
    selectedClasse,
    selectedClassName,
    usePersonalizedAverage,
  } = config;

  const doc = new JsPDF({
    orientation: "l",
    unit: "mm",
    format: "a3",
  });
  let bulletinData;

  await apiClient
    .get(
      "/bulletin/v2/pv/classroom/" +
        bulletinType +
        "/" +
        selectedClasse +
        "/" +
        selectedTrimester
    )
    .then(({ data }) => {
      bulletinData = data;
    });

  const cellToDelete = {};
  const head = [];
  const modulesHead = [];

  const defaultMax = [cellToDelete];
  const defaultMin = [cellToDelete];
  defaultMax.unshift({ content: "اعلى عدد بالقسم", colSpan: 2 }, cellToDelete);
  defaultMin.unshift({ content: "ادنى عدد بالقسم", colSpan: 2 }, cellToDelete);
  let listModules = await getModules(
    selectedLevel,
    bulletinType,
    selectedTrimester
  );

  listModules.forEach((m) => {
    m.subjects.forEach((s) => {
      head.unshift(s.name);
      defaultMax.unshift({ content: "--" });
      defaultMin.unshift({ content: "--" });
    });
    head.unshift("المجموع");
    head.unshift("معدل المجال");
    defaultMax.unshift("", "");
    defaultMin.unshift("", "");

    modulesHead.unshift({
      content: m.name,
      colSpan: m.subjects.length + 2,
    });
    for (let i = 0; i < m.subjects.length + 1; i++)
      modulesHead.unshift(cellToDelete);
  });
  head.unshift("مجموع المجالات");
  head.unshift("الرتبة");
  head.unshift("المعدل");
  head.unshift("الشهادة");
  defaultMax.unshift("", "--", "", "");
  defaultMin.unshift("", "--", "", "");

  modulesHead.unshift({ content: "النتيجة", colSpan: 4 });
  for (let i = 0; i < 3; i++) modulesHead.unshift(cellToDelete);

  const sMax = [cellToDelete];
  const sMin = [cellToDelete];
  sMax.unshift({ content: "اعلى عدد بالقسم", colSpan: 2 }, cellToDelete);
  sMin.unshift({ content: "ادنى عدد بالقسم", colSpan: 2 }, cellToDelete);

  const data = [];
  let studentID = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.unshift(studentID++);
    studentData.unshift(student.fullName);
    let mSum = 0;
    student.modules.forEach((m) => {
      try {
        let sum = 0;
        m.subjects.forEach((s) => {
          studentData.unshift(
            isNumber(s.mark) ? Number(s.mark).toFixed(2) : "--"
          );
          if (isNumber(s.mark)) sum += Number(s.mark);
          if (studentID == 2) {
            sMax.unshift({
              content: isNumber(s.max) ? Number(s.max).toFixed(2) : "--",
            });
            sMin.unshift({
              content: isNumber(s.min) ? Number(s.min).toFixed(2) : "--",
            });
          }
        });
        studentData.unshift(sum.toFixed(2));
        studentData.unshift(m.moyenne);
        if (studentID == 2) {
          sMax.unshift(m.maxMoyenne || "--", "");
          sMin.unshift(m.minMoyenne || "--", "");
        }
        if (m.moyenne != "--") mSum += Number(m.moyenne * m.coef);
      } catch (error) {
        console.log(student, m, error);
      }
    });
    studentData.unshift(mSum.toFixed(2));
    const rank =
      usePersonalizedAverage &&
      bulletinType === "MS" &&
      student.personalizedRank
        ? student.personalizedRank
        : student.rank;
    studentData.unshift(rank);
    const moyenne =
      usePersonalizedAverage && bulletinType === "MS"
        ? student.personalizedMoyenne
        : student.moyenne;

    studentData.unshift(moyenne);
    studentData.unshift(getCertificationNameByMoyenne(moyenne, certifs));

    data.push(studentData);
  });

  const max =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMax
      ? bulletinData.students[0].personalizedMoyenneMax
      : bulletinData.maxMoyenne;

  const min =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMin
      ? bulletinData.students[0].personalizedMoyenneMin
      : bulletinData.minMoyenne;

  sMax.unshift("", max, "", "");
  sMin.unshift("", min, "", "");

  if (bulletinData.students.length === 0) {
    data.push(defaultMax);
    data.push(defaultMin);
  } else {
    data.push(sMax);
    data.push(sMin);
  }
  const array = [
    [...modulesHead, { content: "", colSpan: 2 }, cellToDelete],
    [
      { content: "الشهادة" },
      ...Array(head.length - 1).fill(""),
      { content: bulletinData.classroomName, colSpan: 2 },
      cellToDelete,
    ],
    ...data,
  ];

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  const className = selectedClassName;

  doc.autoTable({
    startY: 5,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      valign: "middle",
      fontSize: 7,
      cellPadding: 1.2,
    },
    rowPageBreak: "avoid",
    columnStyles: {
      0: {
        columnWidth: 20,
      },
    },

    didDrawCell: function (data) {
      const length = data.row.raw.length;
      if (
        data.row.index === 1 &&
        data.column.index < length - 1 &&
        data.column.index !== 0
      ) {
        const width = doc.getTextWidth(head[data.column.index]);
        doc.text(
          head[data.column.index],
          data.cell.x + data.cell.width / 2 - 0.3,
          data.cell.y + 20 + width / 2,
          { angle: 90 }
        );
      }
    },

    didParseCell: function (data) {
      const length = data.row.raw.length;

      if (data.column.index === length - 1) data.cell.styles.fontSize = 11;

      if (data.row.index == 1) {
        data.cell.styles.minCellHeight = 36;
        data.cell.styles.minCellWidth = 5;
        data.cell.styles.halign = "center";
        data.cell.styles.valign = "center";

        if (data.column.index !== length - 1) data.cell.styles.fontSize = 8;
        else data.cell.styles.fontSize = 11;

        if (
          [
            "معدل المجال",
            "المعدل",
            "المجموع",
            "مجموع المجالات",
            "الرتبة",
          ].includes(head[data.column.index])
        ) {
          data.cell.styles.fontStyle = "Bold";
          data.cell.styles.fontSize = 8.5;
        } else data.cell.styles.fontStyle = "normal";

        if ("معدل المجال" == head[data.column.index])
          data.cell.styles.fillColor = "#8bc34a";
        else if ("المعدل" == head[data.column.index])
          data.cell.styles.fillColor = "#ffca62";
      } else {
        if (data.column.index == length - 1) data.cell.styles.fontSize = 8;
      }

      if (data.row.index == 0) {
        data.cell.styles.fontSize = 8;
        data.cell.styles.fillColor = "#ace36d";
      }
      //classname always Amiri
      if (data.row.index == 1 && data.column.index == length - 1)
        data.cell.styles.font = "Amiri";
      else if (/[a-zA-Z]/.test(data.cell.text[0]))
        data.cell.styles.font = "helvetica";
      else if (
        data.row.index !== 1 &&
        (!isNaN(Number(data.cell.text[0])) || data.cell.text[0] == "--")
      ) {
        data.cell.styles.font = "helvetica";
        data.cell.styles.fontStyle = "bold";
      } else data.cell.styles.font = "Amiri";

      //last -1 row
      if (data.row.index == array.length - 2) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#8bc34a";
        else data.cell.styles.fillColor = "#8bc34a";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }
      //last row
      if (data.row.index == array.length - 1) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#f44336";
        else data.cell.styles.fillColor = "#ffaea8";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }
    },
  });

  doc.save(`PV Bulletin ${className}.pdf`);
};

const printPVBulletinExcel = async (config) => {
  console.clear();
  const {
    selectedLevel,
    selectedTrimester,
    bulletinType,
    certifs,
    selectedClasse,
    selectedClassName,
    usePersonalizedAverage,
    document,
    onLevel,
  } = config;
  const btTypeName =
    bulletinType == "MS"
      ? "Specifique"
      : bulletinType == "MT"
      ? "Pilote"
      : "Pedagogique";
  const workbook = new ExcelJS.Workbook();
  let bulletinData;
  let levelData;
  if (onLevel) {
    const levelPromise = [];
    for (let level = -2; level < 14; level++) {
      levelPromise.push(
        apiClient.get(
          "/bulletin/v2/pv/level/" +
            bulletinType +
            "/" +
            level +
            "/" +
            selectedTrimester
        )
      );
    }
    levelData = await Promise.allSettled(levelPromise);
  }
  for (let level = -2; level < 14; level++) {
    if (onLevel) {
      if (levelData[level + 2].status === "fulfilled") {
        try {
          bulletinData = levelData[level + 2].value.data;
        } catch (error) {
          continue;
        }
      } else {
        continue;
      }
    } else {
      await apiClient
        .get(
          "/bulletin/v2/pv/classroom/" +
            bulletinType +
            "/" +
            selectedClasse +
            "/" +
            selectedTrimester
        )
        .then(({ data }) => {
          bulletinData = data;
        });
    }

    let levelName;
    if (onLevel) {
      const levelNames = {
        "-1": "Préscolaire",
        0: " مستوى تحضيري",
        1: "مستوى أولى",
        2: "مستوى ثانية",
        3: "مستوى ثالثة",
        4: "مستوى رابعة",
        5: "مستوى خامسة",
        6: "مستوى سادسة",
        7: "مستوى سابعة",
        8: "مستوى ثامنة",
        9: "مستوى تاسعة",
        10: " مستوى أولى ثانوي ",
        11: " مستوى ثانية ثانوي",
        12: " مستوى ثالثة ثانوي",
        13: " مستوى رابعة ثاتوي",
        other: "أخرى",
      };
      if (levelNames[`${level}`]) levelName = levelNames[`${level}`];
      else levelName = levelNames.autres;
    }
    const worksheet = workbook.addWorksheet(
      onLevel ? levelName : selectedClassName
    );
    const modules = bulletinData.students[0].modules
      .map((m) => ({
        name: m.name,
        subjects: m.subjects.map((s) => s.name),
      }))
      .reverse();
    worksheet.addRow(["", ...modules.map((m) => m.name), ""]); //header row
    const makeHeaderCell = (cell, value = null) => {
      cell.alignment = PvStyles.all.alignment;
      cell.fill = PvStyles.ModuleHeaders;
      cell.border = PvStyles.all.border;
      if (value) cell.value = value;
    };

    /**************************************************
     * Headers Row (module names and class/level name)**
     * *************************************************
     */
    // printing النتيجة
    worksheet.mergeCells(1, 1, 1, 4);
    makeHeaderCell(worksheet.getCell(1, 1), "النتيجة");

    // printing modules names
    let currentColumn = 5;
    modules.forEach((module) => {
      worksheet.mergeCells(
        1,
        currentColumn,
        1,
        currentColumn + module.subjects.length + 1
      );
      makeHeaderCell(worksheet.getCell(1, currentColumn), module.name);
      currentColumn += module.subjects.length + 2;
    });
    worksheet.mergeCells(1, currentColumn, 1, currentColumn + 1);
    makeHeaderCell(worksheet.getCell(1, currentColumn), " ");

    /**************************************************
     * Second Header Row (subject names etc ...)********
     * *************************************************/

    const row = [
      "الشهادة",
      "المعدل",
      "الرتبة",
      "مجموع المجالات",
      ...modules.flatMap((module) => [
        "معدل المجال",
        "المجموع",
        ...module.subjects.reverse(),
      ]),
    ]; // second header row
    worksheet.addRow(row);
    worksheet.getCell(2, 2).fill = PvStyles.secondHeader.moy; //المعدل
    worksheet.getCell(2, 2).alignment = { textRotation: 90 };
    worksheet.getCell(2, 3).fill = PvStyles.secondHeader.moduleTotal; //الرتبة
    worksheet.getCell(2, 3).alignment = { textRotation: 90 };
    worksheet.getCell(2, 4).fill = PvStyles.secondHeader.moduleTotal; //مجموع المجالات
    worksheet.getCell(2, 4).alignment = { textRotation: 90 };
    currentColumn = 5;
    modules.forEach((module) => {
      worksheet.getCell(2, currentColumn).fill =
        PvStyles.secondHeader.moduleMoy; //معدل المجال"
      worksheet.getCell(2, currentColumn).alignment = { textRotation: 90 };
      worksheet.getCell(2, currentColumn + 1).alignment = { textRotation: 90 };
      worksheet.getCell(2, currentColumn + 1).fill =
        PvStyles.secondHeader.moduleTotal; //المجموع
      currentColumn += 2;
      module.subjects.forEach((_) => {
        worksheet.getCell(2, currentColumn).fill =
          PvStyles.secondHeader.subject; // subject
        worksheet.getCell(2, currentColumn).alignment = { textRotation: 90 };
        currentColumn++;
      });
    });
    worksheet.mergeCells(2, currentColumn, 2, currentColumn + 1);
    worksheet.getCell(2, currentColumn).value =
      levelName || selectedClassName || "";
    worksheet.getCell(2, currentColumn).fill =
      PvStyles.secondHeader.moduleTotal;

    /***************************
     * Students marks etc*******
     * *************************/
    let studentID = 0;
    let studentTemplate;
    let certifLength = 6;
    let studentLenght = 6;
    let classroomLenght = 6;
    let maxRow = [];
    let minRow = [];
    bulletinData.students.forEach((student) => {
      const studentData = [];
      if (onLevel) {
        studentData.unshift(student.fullName);
        studentData.unshift(student.classroomName);
        studentID++;
      } else {
        studentData.unshift(++studentID);
        studentData.unshift(student.fullName);
      }

      if (student.fullName.length > studentLenght)
        studentLenght = student.fullName.length;
      if (student.classroomName.length > classroomLenght)
        classroomLenght = student.classroomName.length;
      let mSum = 0;
      student.modules.forEach((m) => {
        try {
          let sum = 0;
          m.subjects.forEach((s) => {
            studentData.unshift(
              isNumber(s.mark) ? Number(Number(s.mark).toFixed(2)) : "--"
            );
            if (isNumber(s.mark)) sum += Number(s.mark);
            if (studentID == 1) {
              maxRow.unshift(
                isNumber(s.max) ? Number(Number(s.max).toFixed(2)) : "--"
              );
              minRow.unshift(
                isNumber(s.min) ? Number(Number(s.min).toFixed(2)) : "--"
              );
            }
          });

          studentData.unshift(Number(sum.toFixed(2)));
          studentData.unshift(isNumber(m.moyenne) ? Number(m.moyenne) : "--");
          if (m.moyenne != "--") mSum += Number(m.moyenne * m.coef);
          if (studentID == 1) {
            maxRow.unshift("");
            minRow.unshift("");
            maxRow.unshift(
              isNumber(m.maxMoyenne) ? Number(m.maxMoyenne) : "--"
            );
            minRow.unshift(
              isNumber(m.minMoyenne) ? Number(m.minMoyenne) : "--"
            );
          }
        } catch (error) {
          console.log(student, m, error);
        }
      });
      studentData.unshift(Number(mSum.toFixed(2)));
      const rank =
        usePersonalizedAverage &&
        bulletinType === "MS" &&
        student.personalizedRank
          ? student.personalizedRank
          : student.rank;
      studentData.unshift(rank);
      const moyenne =
        usePersonalizedAverage && bulletinType === "MS"
          ? student.personalizedMoyenne
          : student.moyenne;
      studentData.unshift(isNumber(moyenne) ? Number(moyenne) : "--");
      const certif = getCertificationNameByMoyenne(moyenne, certifs);
      studentData.unshift(certif);
      if (certif.length > certifLength) certifLength = certif.length;
      if (studentID == 1) {
        const minMoy =
          usePersonalizedAverage && bulletinType === "MS"
            ? student.personalizedMoyenneMin
            : student.minMoyenne;
        const maxMoy =
          usePersonalizedAverage && bulletinType === "MS"
            ? student.personalizedMoyenneMax
            : student.maxMoyenne;
        maxRow.unshift("");
        maxRow.unshift("");
        minRow.unshift("");
        minRow.unshift("");
        maxRow.unshift(isNumber(maxMoy) ? Number(maxMoy) : "--");
        minRow.unshift(isNumber(minMoy) ? Number(minMoy) : "--");
        studentTemplate = studentData;
      }
      worksheet.addRow(studentData);
    });
    maxRow.unshift("");
    maxRow.push("اعلى عدد بالقسم");
    minRow.unshift("");
    minRow.push("ادنى عدد بالقسم");
    worksheet.addRow(maxRow);
    worksheet.addRow(minRow);
    worksheet.mergeCells(
      bulletinData.students.length + 3,
      studentTemplate.length - 1,
      bulletinData.students.length + 3,
      studentTemplate.length
    );
    worksheet.mergeCells(
      bulletinData.students.length + 4,
      studentTemplate.length - 1,
      bulletinData.students.length + 4,
      studentTemplate.length
    );

    /*****************************************************
     * Bordering and text alignment marks etc**************
     * ***************************************************/
    for (let i = 1; i < worksheet.actualRowCount + 1; i++) {
      const row = worksheet.getRow(i);
      row.eachCell((cell) => {
        cell.font = { ...cell.font, name: "Arial" };
        if (i == 1) cell.font = { ...cell.font, bold: true };
        if (i > 2) {
          if (![1, studentTemplate.length + 1].includes(cell.col)) {
            cell.fill = PvStyles.secondHeader.moduleTotal;
          }
          //if (i % 2 == 1) {
          //  cell.fill = cell.fill
          //    ? {
          //        ...cell.fill,
          //        fgColor: { argb: "DBDBDB" },
          //      }
          //    : {
          //        fgColor: { argb: "DBDBDB" },
          //      };
          //}
        }
        cell.border = PvStyles.all.border;

        if (onLevel && cell.col == studentTemplate.length + 1 && i > 2)
          cell.alignment = {
            ...cell.alignment,
            ...PvStyles.all.nameAlignment,
          };
        else if (!onLevel && cell.col == studentTemplate.length && i > 2)
          cell.alignment = {
            ...cell.alignment,
            ...PvStyles.all.nameAlignment,
          };
        else
          cell.alignment = {
            ...cell.alignment,
            ...PvStyles.all.alignment,
          };
        if (i == worksheet.actualRowCount - 1) {
          if (isNumber(cell.value))
            cell.font = { ...cell.font, color: { argb: "8BC34A" } };
          else if (cell.value.length > 5) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "8CC24A" },
            };
          }
        }
        if (i == worksheet.actualRowCount) {
          if (isNumber(cell.value))
            cell.font = { ...cell.font, color: { argb: "F44336" } };
          else if (cell.value.length > 5) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFADA8" },
            };
          }
        }
      });
    }

    worksheet.getColumn(1).width = certifLength + 2;
    if (onLevel) {
      worksheet.getColumn(studentTemplate.length - 1).width =
        classroomLenght + 4;
      worksheet.getColumn(studentTemplate.length).width = studentLenght + 4;
    } else {
      worksheet.getColumn(studentTemplate.length - 1).width = studentLenght + 4;
    }
    if (!onLevel) break;
  }
  if (onLevel) workbook.worksheets[0].destroy();
  // exporting process
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `PV ${btTypeName}${
    onLevel ? "" : " - " + selectedClassName
  }.xlsx`;
  link.click();
};
const printPVBulletinLevel = async (config) => {
  const {
    selectedLevel,
    schoolDetails,
    selectedStudent,
    selectedStudentName,
    settings,
    type,
    selectedTrimester,
    bulletinType,
    certifs,
    selectedClasse,
    selectedClassName,
    usePersonalizedAverage,
  } = config;

  const doc = new JsPDF({
    orientation: "l",
    unit: "mm",
    format: "a3",
  });
  let bulletinData;

  await apiClient
    .get(
      "/bulletin/v2/pv/level/" +
        bulletinType +
        "/" +
        selectedLevel +
        "/" +
        selectedTrimester
    )
    .then(({ data }) => {
      bulletinData = data;
    });

  const cellToDelete = {};
  const head = [];
  const modulesHead = [];

  const defaultMax = [cellToDelete];
  const defaultMin = [cellToDelete];
  defaultMax.unshift(
    { content: "اعلى عدد بالمستوى", colSpan: 2 },
    cellToDelete
  );
  defaultMin.unshift(
    { content: "ادنى عدد بالمستوى", colSpan: 2 },
    cellToDelete
  );
  let listModules = await getModules(
    selectedLevel,
    bulletinType,
    selectedTrimester
  );

  listModules.forEach((m) => {
    m.subjects.forEach((s) => {
      head.unshift(s.name);
      defaultMax.unshift({ content: "--" });
      defaultMin.unshift({ content: "--" });
    });
    head.unshift("المجموع");
    head.unshift("معدل المجال");
    defaultMax.unshift("", "");
    defaultMin.unshift("", "");

    modulesHead.unshift({
      content: m.name,
      colSpan: m.subjects.length + 2,
    });
    for (let i = 0; i < m.subjects.length + 1; i++)
      modulesHead.unshift(cellToDelete);
  });
  head.unshift("مجموع المجالات");
  head.unshift("الرتبة");
  head.unshift("المعدل");
  head.unshift("الشهادة");
  defaultMax.unshift("", "--", "", "");
  defaultMin.unshift("", "--", "", "");

  modulesHead.unshift({ content: "النتيجة", colSpan: 4 });
  for (let i = 0; i < 3; i++) modulesHead.unshift(cellToDelete);

  const sMax = [cellToDelete];
  const sMin = [cellToDelete];
  sMax.unshift({ content: "اعلى عدد بالمستوى", colSpan: 2 }, cellToDelete);
  sMin.unshift({ content: "ادنى عدد بالمستوى", colSpan: 2 }, cellToDelete);

  const data = [];
  let studentID = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.unshift(student.fullName);
    studentData.unshift(student.classroomName);
    let mSum = 0;
    studentID++;
    student.modules.forEach((m) => {
      let sum = 0;
      m.subjects.forEach((s) => {
        studentData.unshift(
          isNumber(s.mark) ? Number(s.mark).toFixed(2) : s.mark
        );
        if (isNumber(s.mark)) sum += Number(s.mark);

        if (studentID == 2) {
          sMax.unshift({
            content: isNumber(s.max) ? Number(s.max).toFixed(2) : "--",
          });
          sMin.unshift({
            content: isNumber(s.min) ? Number(s.min).toFixed(2) : "--",
          });
        }
      });
      studentData.unshift(sum.toFixed(2));
      studentData.unshift(m.moyenne);
      if (studentID == 2) {
        sMax.unshift(m.maxMoyenne || "--", "");
        sMin.unshift(m.minMoyenne || "--", "");
      }
      if (m.moyenne != "--") mSum += Number(m.moyenne * m.coef);
    });
    studentData.unshift(mSum.toFixed(2));
    const rank =
      usePersonalizedAverage &&
      bulletinType === "MS" &&
      student.personalizedRank
        ? student.personalizedRank
        : student.rank;
    studentData.unshift(rank);

    const moyenne =
      usePersonalizedAverage && bulletinType === "MS"
        ? student.personalizedMoyenne
        : student.moyenne;
    studentData.unshift(moyenne);
    studentData.unshift(getCertificationNameByMoyenne(moyenne, certifs));

    data.push(studentData);
  });

  const max =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMax
      ? bulletinData.students[0].personalizedMoyenneMax
      : bulletinData.maxMoyenne;

  const min =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMin
      ? bulletinData.students[0].personalizedMoyenneMin
      : bulletinData.minMoyenne;

  sMax.unshift("", max, "", "");
  sMin.unshift("", min, "", "");

  if (bulletinData.students.length === 0) {
    data.push(defaultMax);
    data.push(defaultMin);
  } else {
    data.push(sMax);
    data.push(sMin);
  }
  const array = [
    [...modulesHead, { content: "", colSpan: 2 }, cellToDelete],
    [
      { content: "الشهادة" },
      ...Array(head.length - 1).fill(""),
      { content: selectedLevel + " المستوى", colSpan: 2 },
      cellToDelete,
    ],
    ...data,
  ];

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  const className = selectedClassName;

  doc.autoTable({
    startY: 5,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      valign: "middle",
      fontSize: 7,
      cellPadding: 1.2,
    },
    rowPageBreak: "avoid",
    columnStyles: {
      0: {
        columnWidth: 20,
      },
    },

    didDrawCell: function (data) {
      const length = data.row.raw.length;
      if (
        data.row.index === 1 &&
        data.column.index < length - 1 &&
        data.column.index !== 0
      ) {
        const width = doc.getTextWidth(head[data.column.index]);
        doc.text(
          head[data.column.index],
          data.cell.x + data.cell.width / 2 - 0.3,
          data.cell.y + 20 + width / 2,
          { angle: 90 }
        );
      }
    },

    didParseCell: function (data) {
      const length = data.row.raw.length;

      if (data.column.index === length - 1) data.cell.styles.fontSize = 11;

      if (data.row.index == 1) {
        data.cell.styles.minCellHeight = 36;
        data.cell.styles.minCellWidth = 5;
        data.cell.styles.halign = "center";
        data.cell.styles.valign = "center";

        if (data.column.index !== length - 1) data.cell.styles.fontSize = 8;
        else data.cell.styles.fontSize = 11;

        if (
          [
            "معدل المجال",
            "المعدل",
            "المجموع",
            "مجموع المجالات",
            "الرتبة",
          ].includes(head[data.column.index])
        ) {
          data.cell.styles.fontStyle = "Bold";
          data.cell.styles.fontSize = 8.5;
        } else data.cell.styles.fontStyle = "normal";

        if ("معدل المجال" == head[data.column.index])
          data.cell.styles.fillColor = "#8bc34a";
        else if ("المعدل" == head[data.column.index])
          data.cell.styles.fillColor = "#ffca62";
      } else {
        if (data.column.index == length - 1) data.cell.styles.fontSize = 8;
      }

      if (data.row.index == 0) {
        data.cell.styles.fontSize = 8;
        data.cell.styles.fillColor = "#ace36d";
      }
      //classname always Amiri
      if (data.row.index == 1 && data.column.index == length - 1)
        data.cell.styles.font = "Amiri";
      else if (/[a-zA-Z]/.test(data.cell.text[0]))
        data.cell.styles.font = "helvetica";
      else if (
        data.row.index !== 1 &&
        (!isNaN(Number(data.cell.text[0])) || data.cell.text[0] == "--")
      ) {
        data.cell.styles.font = "helvetica";
        data.cell.styles.fontStyle = "bold";
      } else data.cell.styles.font = "Amiri";

      if (data.row.index == array.length - 2) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#8bc34a";
        else data.cell.styles.fillColor = "#8bc34a";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }

      if (data.row.index == array.length - 1) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#f44336";
        else data.cell.styles.fillColor = "#ffaea8";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }
    },
  });

  doc.save(`PV Bulletin par niveau ${selectedLevel}.pdf`);
};

const printPVAnnuel = async (config) => {
  this.$gtag.event("Imp PV Annuel", {
    event_category: "Impression PDF",
    event_label: "Bulletin peda-NotesEleves section",
    value: 1,
  });

  const cellToDelete = {};

  const doc = new JsPDF({
    orientation: "m",
    unit: "mm",
    format: "a3",
  });

  doc.setFont("Amiri");

  const pdf_width = doc.internal.pageSize.getWidth();

  let arrayOfImages = this.settings.image;
  for (var i = 0; i < arrayOfImages.length; i++) {
    if (this.settings.activeBuilding === arrayOfImages[i].db) {
      var imgData = new Image();
      imgData.src = arrayOfImages[i].logo;
    }
  }

  doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

  const className = selectedClassName;

  doc.text("PV Annuel - " + className, pdf_width / 2, 20, {
    align: "center",
  });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  doc.setFontSize(9);

  doc.text(new Date().toLocaleDateString("fr-FR", options), pdf_width - 17, 8, {
    align: "right",
  });

  let bulletinData;

  await apiClient
    .get("/bulletin/final/all/" + selectedClasse)
    .then((res) => (bulletinData = res.data));

  let array = [
    [
      { content: "الرتبة", styles: { fontStyle: "Bold" } },
      { content: "معدل العام", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
      { content: "الأسم", styles: { fontStyle: "Bold" } },
      { content: "#", styles: { fontStyle: "Bold" } },
    ],
  ];

  let id = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.push(student.rank);
    studentData.push(student.finalMoyenne);
    studentData.push(student.moyenneThree);
    studentData.push(student.moyenneTwo);
    studentData.push(student.moyenneOne);
    studentData.push(student.fullName);
    studentData.push(id++);
    array.push(studentData);
  });

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  doc.autoTable({
    startY: 35,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      fontSize: 10,
      font: "Amiri",
      // cellPadding: 1.2,
    },
    columnStyles: {
      0: { cellWidth: 20 },
    },
    rowPageBreak: "avoid",
  });

  //page numbering
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(9); //Optional

  doc.setTextColor(0, 0, 0);

  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2;
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  //end page numbering

  doc.save(`PV Annuel ${className}.pdf`);
};

const printPVAnnuelByLevel = async (config) => {
  this.$gtag.event("Imp PV Annuel by level", {
    event_category: "Impression PDF",
    event_label: "Bulletin peda-NotesEleves section",
    value: 1,
  });

  const cellToDelete = {};

  const doc = new JsPDF({
    orientation: "m",
    unit: "mm",
    format: "a3",
  });

  doc.setFont("Amiri");

  const pdf_width = doc.internal.pageSize.getWidth();

  let arrayOfImages = this.settings.image;
  for (var i = 0; i < arrayOfImages.length; i++) {
    if (this.settings.activeBuilding === arrayOfImages[i].db) {
      var imgData = new Image();
      imgData.src = arrayOfImages[i].logo;
    }
  }

  doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

  const levels = {
    "-2": "Préscolaire",
    "-1": "تحضيري",
    1: "سنة أولى",
    2: "سنة ثانية",
    3: "سنة ثالثة",
    4: "سنة رابعة",
    5: "سنة خامسة",
    6: "سنة سادسة",
    7: "سنة سابعة",
    8: "سنة ثامنة",
    9: "سنة تاسعة",
    10: "أولى ثانوي",
    11: "ثانية ثانوي",
    12: "ثالثة ثانوي",
    13: "رابعة ثاتوي",
  };

  doc.text("PV Annuel - " + levels[selectedLevel], pdf_width / 2, 20, {
    align: "center",
  });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  doc.setFontSize(9);

  doc.text(new Date().toLocaleDateString("fr-FR", options), pdf_width - 17, 8, {
    align: "right",
  });

  let bulletinData;

  await apiClient
    .get("/bulletin/pv/final/" + selectedLevel)
    .then((res) => (bulletinData = res.data));

  let array = [
    [
      { content: "الرتبة", styles: { fontStyle: "Bold" } },
      { content: "معدل العام", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
      { content: "القسم", styles: { fontStyle: "Bold" } },
      { content: "الأسم", styles: { fontStyle: "Bold" } },
    ],
  ];

  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.push(student.rank);
    studentData.push(student.finalMoyenne);
    studentData.push(student.moyenneThree);
    studentData.push(student.moyenneTwo);
    studentData.push(student.moyenneOne);
    studentData.push(student.classroomName);
    studentData.push(student.fullName);
    array.push(studentData);
  });

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  doc.autoTable({
    startY: 35,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      fontSize: 9,
      font: "Amiri",
      // cellPadding: 1.2,
    },
    columnStyles: {
      0: { cellWidth: 20 },
    },
    rowPageBreak: "avoid",
  });

  //page numbering
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(9); //Optional

  doc.setTextColor(0, 0, 0);

  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2;
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  //end page numbering

  doc.save(`PV Annuel ${levels[selectedLevel]}.pdf`);
};

export default {
  printPVBulletin,
  printPVBulletinLevel,
  printPVBulletinExcel,
  // printPVAnnuel,
  // printPVAnnuelByLevel,
};
