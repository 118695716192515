<template>
  <div>
    <a-modal
      :dialog-style="{ top: '10px' }"
      :title="
        $t('appreciation.adminAppreciation') +
        ': ' +
        selectedStudentRecord.fullName
      "
      :visible="visibleAppreciationModal"
      width="90%"
      height="90%"
      @cancel="() => (visibleAppreciationModal = false)"
      @ok="() => (visibleAppreciationModal = false)"
    >
      <div style="width: 100%; height: 70vh">
        <div class="row" style="height: 100%">
          <div class="col-7">
            <div
              v-if="loadingPdfPreview"
              style="
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <a-col :span="16" :offset="16">
                <a-spin size="large" />
              </a-col>
            </div>
            <iframe
              v-else
              :src="pdfBlobUrl"
              style="background-color: #eee; height: 100%; width: 100%"
            >
            </iframe>
          </div>
          <div class="col-1" style="flex: 0 0 1.333333%">
            <a-divider type="vertical" style="height: 100%" />
          </div>
          <div class="col-4">
            <a-divider>ملاحظات المدير(ة) حول السّلوك و المواظبة</a-divider>
            <a-textarea
              :maxLength="140"
              :disabled="loadingPdfPreview"
              v-model="currentAppreciation"
              placeholder="ملاحظات المدير(ة) حول السّلوك و المواظبة"
              :auto-size="{ minRows: 4, maxRows: 6 }"
            />
            <div class="row mt-3">
              <div class="col m-auto test-center">
                <a-button
                  :loading="loadingDelete"
                  :disabled="loadingDelete"
                  @click="
                    () => {
                      deleteStudentAppreciation(selectedStudentRecord._id);
                    }
                  "
                  icon="delete"
                  type="danger"
                  style="width: 100%"
                  >{{ $t("action.supprimer") }}</a-button
                >
              </div>
              <div class="col m-auto test-center">
                <a-button
                  icon="save"
                  type="primary"
                  :loading="loadingSave"
                  :disabled="loadingSave"
                  @click="
                    () => {
                      saveStudentAppreciation(selectedStudentRecord._id);
                    }
                  "
                  style="width: 100%"
                  >{{ $t("action.enregistrer") }}</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <div>
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="margin-bottom: 30px">
            <span style="margin-right: 30px">{{
              $t("appreciation.adminAppreciation")
            }}</span>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <label>{{ $t("bulletin.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 10px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all">---</a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
              >{{ classe.name }}</a-select-option
            >
          </a-select>
        </div>

        <div class="col-xl-4">
          <label style="margin-right: 5px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100px; margin-left: 15px; margin-right: 20px"
            @change="
              (val) => {
                this.selectedTrimester = val;
                this.updateBulletins();
                this.updateAdminAppreciations();
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
              >{{ trimester }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <a-divider></a-divider>
      <div class="row">
        <div class="col-xl-12">
          <div class="card" v-if="selectedClasse">
            <div class="data-table">
              <a-table
                @change="tableChanged"
                :loading="tableLoading"
                :pagination="false"
                :data-source="activeData"
                :columns="columns"
                :rowClassName="() => 'editable-row'"
                :customRow="
                  (record) => {
                    return {
                      on: {
                        click: (event) => {
                          this.onStudentClick(record);
                        },
                      },
                    };
                  }
                "
              >
                <template slot="moyenne" slot-scope="text, record">
                  {{ getStudentMoyenne(record._id) }}
                </template>

                <template slot="appreciation" slot-scope="text, record">
                  {{ getStudentAppreciation(record._id) }}
                </template>

                <template slot="name" slot-scope="text">
                  <span class="arabic-font">{{ text }}</span>
                </template>

                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                    >{{ $t("paiement.chercher") }}</a-button
                  >
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                    >{{ $t("action.reinitialiser") }}</a-button
                  >
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ text }}</template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";
const bulletinHeplers =
  require("./../studentMarks/scripts/bulletinHeplers/peda").default;
export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletin: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data))
      .catch((e) => {
        console.log(e);
      });

    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;
    this.schoolType = this.schoolDetails.type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });
    this.updateCertifParams();
  },
  data() {
    return {
      pdfBlobUrl: "",
      certifParams: [],
      visibleAllFinalBulletin: false,
      bulletinDate: new Date(),
      moyenne: "--",
      schoolType: null,
      schoolDetails: {},
      filtredTable: [],
      selectedStudent: this.$t("menu.liste"),
      selectedStudentName: "",
      studentLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      loadingPdfPreview: false,
      activeData: [],
      appreciations: [],
      loadingSave: false,
      loadingDelete: false,
      currentAppreciation: "",
      visibleAppreciationModal: false,
      selectedStudentRecord: {},
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.moyenne"),
          dataIndex: "moyenne",
          scopedSlots: { customRender: "moyenne" },
          sorter: (a, b) =>
            Number(this.getStudentMoyenne(a._id)) -
            Number(this.getStudentMoyenne(b._id)),
        },
        {
          title: this.$t("appreciation.appreciation"),
          dataIndex: "appreciation",
          scopedSlots: { customRender: "appreciation" },
        },
      ],
      listModules: [],
      selectedLevel: null,
      selectedClassName: "",
      bulletinData: {},
    };
  },
  methods: {
    moment,
    async onStudentClick(record) {
      try {
        this.selectedStudentRecord = record;
        this.visibleAppreciationModal = true;
        this.loadingPdfPreview = true;

        let studentApp = this.appreciations.find(
          (el) => String(el.student) == record._id
        );
        this.currentAppreciation = studentApp ? studentApp.appreciation : "";
        this.showPreview(record);
      } catch (error) {
        console.error(error);
      }
    },
    getStudentMoyenne(studentID) {
      try {
        if (!this.bulletinData) return "--";
        const student = this.bulletinData.students.find(
          (el) => el.id == studentID
        );
        return student ? student.moyenne : "--";
      } catch (error) {
        return "--";
      }
    },
    getStudentAppreciation(studentID) {
      if (this.appreciations.length == 0) return "--";
      const app = this.appreciations.find(
        (el) => String(el.student) == studentID
      );
      return app ? app.appreciation : "--";
    },

    async updateAdminAppreciations() {
      await apiClient
        .post("/adminAppreciation/filter", {
          query: {
            schoolarYear: this.settings.activeSchoolarYear,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
          },
        })
        .then(({ data }) => {
          this.appreciations = data;
        });
    },

    async saveStudentAppreciation(studentID) {
      this.loadingSave = true;
      const app = this.appreciations.find(
        (el) => String(el.student) == studentID
      );

      if (app) {
        await apiClient
          .patch("/adminAppreciation/" + app._id, {
            appreciation: this.currentAppreciation,
          })
          .then((res) => {
            app.appreciation = this.currentAppreciation;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await apiClient
          .put("/adminAppreciation", {
            appreciation: this.currentAppreciation,
            student: studentID,
            trimester: this.selectedTrimester,
            schoolarYear: this.settings.activeSchoolarYear,
            classroom: this.selectedClasse,
          })
          .then((res) => {
            this.appreciations.push(res.data);
          });
      }

      this.$message.success(this.$t("appreciation.saved"));
      await this.updateBulletins();
      this.showPreview(this.selectedStudentRecord, true);
      this.loadingSave = false;
    },

    async deleteStudentAppreciation(studentID) {
      this.loadingDelete = true;
      const app = this.appreciations.find(
        (el) => String(el.student) == studentID
      );

      if (app)
        await apiClient.delete("/adminAppreciation/" + app._id).then((res) => {
          this.appreciations = this.appreciations.filter(
            (el) => el._id != app._id
          );
        });

      this.$message.success(this.$t("appreciation.deleted"));

      this.currentAppreciation = "";

      this.showPreview(this.selectedStudentRecord, true);
      this.loadingDelete = false;
    },

    async updateCertifParams() {
      await apiClient
        .get("/certification/peda")
        .then((res) => {
          this.certifParams = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCertificationNameByMoyenne(moyenne) {
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert.name;
      return "";
    },

    async updateBulletins() {
      this.tableLoading = true;
      await apiClient
        .get(
          "/bulletin/v2/bt/MP/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletinData = data;
        })
        .catch((error) => {
          console.error("error ", error);
        })
        .finally(() => (this.tableLoading = false));
    },

    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async handleClassChange(on, value) {
      this.selectedStudent = null;
      this.selectedStudentName = "";
      this.studentLoading = true;
      this.tableLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstName: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.filtredTable = this.activeData;
          this.studentLoading = false;
        });

      await this.updateBulletins();
      this.updateAdminAppreciations();

      this.tableLoading = false;
    },

    //generate bulletin pdf
    async showPreview(student, isPreview = false) {
      try {
        let config = {
          selectedLevel: this.selectedLevel,
          schoolDetails: this.schoolDetails,
          selectedStudent: student._id,
          bulletinDate: this.bulletinDate,
          certifs: this.certifParams,
          selectedClassName: this.selectedClassName,
          schoolarYearName: this.schoolarYearName,
          selectedTrimester: this.selectedTrimester,
          selectedStudentName: student.fullName,
          settings: this.settings,
          selectedClasse: this.selectedClasse,
        };
        let btData = this.bulletinData.students.find(
          (el) => el.id == student._id
        );
        const doc = await bulletinHeplers.printBulletin(
          false,
          config,
          true,
          btData
        );
        this.pdfBlobUrl = doc.output("bloburl");
        this.loadingPdfPreview = false;
      } catch (error) {
        console.error(error);
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.arabic-font {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

::v-deep .editable-row:hover {
  cursor: pointer;
}
</style>
